const MEET_AGENT = {
  en: 'Meet your assistant',
  zh: '认识您的助理',
}

const ENHANCE_RESEARCH = {
  en: 'Enhance your research efficiency',
  zh: '加速您的研究效率',
}

const DAILY_LIMIT_ERROR = {
  en: 'You have reached your monthly pro usage limit',
  zh: '您已达到每月专业版使用限制',
}

const DAILY_LIMIT_HINT1 = {
  en: 'The daily limit for your trial account is 50 questions per month.',
  zh: '您的试用帐户每月限制为50个问题。',
}

const DAILY_LIMIT_HINT2 = {
  en: 'You can upgrade your account to continue',
  zh: '您可以升级您的帐户以继续使用。',
}

const NO_AGENT_TYPE = {
  en: 'No content found',
  zh: '未找到内容',
}

const CONNECTION_CLOSED = {
  en: 'The connection was closed. Please refresh the page to continue.',
  zh: '连接已关闭。请刷新页面继续使用。',
}

const CONNECTION_ERROR = {
  en: 'Connection error. Please refresh the page to continue.',
  zh: '连接错误。请刷新页面继续使用。',
}

const CHAT = {
  en: 'Chat',
  zh: '对话',
  jp: 'チャット',
}

const CREATE_NEW = {
  en: 'create new',
  zh: '新建',
  jp: '新規作成',
}

const TYPE_MESSAGE = {
  en: 'Type your message...',
  zh: '输入您的消息...',
  jp: 'メッセージを入力...',
}

const LOADING_MORE = {
  en: 'Loading more',
  zh: '加载更多...',
  jp: 'もっと読み込む...',
}

const LOAD_MORE = {
  en: 'Load More',
  zh: '加载更多',
  jp: 'もっと読み込む',
}

const END_OF_DATA = {
  en: 'End of data',
  zh: '数据已加载完',
  jp: 'データの終わり',
}

const TOTAL_PAPERS = {
  en: 'papers total',
  zh: '篇论文',
  jp: '論文の合計',
}

const SHOWING_PAPERS = {
  en: 'Showing',
  zh: '显示',
  jp: '表示中',
}

const PAPERS = {
  en: 'papers',
  zh: '篇论文',
  jp: '論文',
}

const SELECT_ALL = {
  en: 'Select All',
  zh: '全选',
  jp: 'すべて選択',
}

const DESELECT_ALL = {
  en: 'Deselect All',
  zh: '取消全选',
  jp: 'すべて選択解除',
}

const HIDE_PANEL = {
  en: 'Hide Panel',
  zh: '隐藏面板',
  jp: 'パネルを隠す',
}

const SHOW_PANEL = {
  en: 'Show Panel',
  zh: '显示面板',
  jp: 'パネルを表示',
}

const GRID = {
  en: 'Card',
  zh: '卡片',
  jp: 'カード',
}

const TABLE = {
  en: 'Table',
  zh: '表格',
  jp: 'テーブル',
}

const MAX_SELECT_ERROR = {
  en: 'You can only select up to',
  zh: '最多只能选择',
  jp: '最大で選択できるのは',
}

const ITEMS = {
  en: 'items',
  zh: '项',
  jp: '項目',
}

const HINT_CONFERENCE = {
  en: 'Selected abstracts ready for analysis. Choose a question below or ask your own',
  zh: '您可以对勾选的摘要进行分析或提问',
  jp: '選択された要約の分析準備が整いました。以下から質問を選ぶか、独自の質問をしてください',
}

const HINT_CLINICAL_RESULT = {
  en: 'Selected clinical data ready for analysis. Choose a question below or ask your own',
  zh: '您可以对勾选的临床数据进行分析或提问',
  jp: '選択された臨床データの分析準備が整いました。以下から質問を選ぶか、独自の質問をしてください',
}

const HINT_DRUG_COMPETE = {
  en: 'Selected drugs ready for analysis. Choose a question below or ask your own',
  zh: '您可以对勾选的药物进行分析或提问',
  jp: '選択された薬剤の分析準備が整いました。以下から質問を選ぶか、独自の質問をしてください',
}

const SEARCH_AND_FILTER = {
  en: 'Search & Filter',
  zh: '搜索和筛选',
  jp: '検索とフィルター',
}

const SELECTED_COUNT = {
  en: 'selected',
  zh: '已选择',
  jp: '選択済み',
}

const FILTER_BY = {
  en: 'Filter by',
  zh: '按照',
  jp: 'フィルター',
}

const CLEAR_ALL = {
  en: 'Clear All',
  zh: '清除全部',
  jp: 'すべてクリア',
}

const SEARCH = {
  en: 'Search',
  zh: '搜索',
  jp: '検索',
}

const MORE = {
  en: 'more',
  zh: '更多',
  jp: 'その他',
}

const TOTAL_RESULTS_COUNT = {
  en: (count) => `Found ${count} ${count === 1 ? 'result' : 'results'}`,
  zh: '共找到 {count} 条结果',
  jp: '検索結果 {count} 件'
}

export const i18n = {
  MEET_AGENT,
  ENHANCE_RESEARCH,
  DAILY_LIMIT_ERROR,
  DAILY_LIMIT_HINT1,
  DAILY_LIMIT_HINT2,
  NO_AGENT_TYPE,
  CONNECTION_CLOSED,
  CONNECTION_ERROR,
  CHAT,
  CREATE_NEW,
  TYPE_MESSAGE,
  LOADING_MORE,
  LOAD_MORE,
  END_OF_DATA,
  TOTAL_PAPERS,
  SHOWING_PAPERS,
  PAPERS,
  SELECT_ALL,
  DESELECT_ALL,
  HIDE_PANEL,
  SHOW_PANEL,
  GRID,
  TABLE,
  MAX_SELECT_ERROR,
  ITEMS,
  HINT_CONFERENCE,
  HINT_CLINICAL_RESULT,
  HINT_DRUG_COMPETE,
  SEARCH_AND_FILTER,
  SELECTED_COUNT,
  FILTER_BY,
  CLEAR_ALL,
  SEARCH,
  MORE,
  TOTAL_RESULTS_COUNT,
}